var anchoventana=$(document).width();
var altoventana=$(document).height();
var md=768;

(function($) {
  var Sage = {
    'common': {
      init: function() {
        
         initPlugins();
         initListeners();

        $('.js-uploader').click(function(event) {
          /* Act on the event */
          _nom_input = $(this).data('fichero');
           $('#'+_nom_input).trigger('click');
        });

        $('.fichero').on('change', function(){
           idfichero = $(this).attr('id');

           _fich = $("[data-fichero='"+idfichero+"']" ).attr('id');
           $('#'+_fich).addClass('ticket-ok');
           _nom_tmp = $(this).val().split("\\");
           _nom = _nom_tmp[_nom_tmp.length-1];
          // 
           var fileSize = this.files[0].size;
           var fileName = this.files[0].name;
           _ext = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
           $(this).closest('.form-group').attr('data-tipo-error', '0');//no hay error
           if (fileSize > 10000000){//error tamaño
             $(this).closest('.form-group').attr('data-tipo-error', '2');
             $(this).closest('.form-group').addClass('has-error');
             $(this).closest('.form-group').removeClass('has-sucess');
           }

           _ext = _nom.slice((_nom.lastIndexOf(".") - 1 >>> 0) + 2);
           var validos = ['png', 'jpg', 'pdf', 'jpeg', 'PNG', 'JPG', 'JPEG', 'PDF'];
           if ($.inArray(_ext, validos) < 0){//error extensión
             $(this).closest('.form-group').attr('data-tipo-error', '3');
             $(this).closest('.form-group').addClass('has-error');
             $(this).closest('.form-group').removeClass('has-sucess');
           } 



           _target = $(this).attr('data-targetFichero');
           //alert(_target);
           $('#'+_target).val(_nom);
           $('.fa-check-ok').removeClass('d-none');
        });


        /**
         * js del proyecto
        */
        $('.js-close').on('click', function(){
          $(this).closest('.alert').hide();
        });

        $('.js-categoria').on('click', function(){
          //$(this).find('.capa').toggleClass('categoria-activa');
          _cat = $(this).attr('data-categoria');
          
          if (!$(this).find('.capa').hasClass('categoria-activa')){
            $(this).find('.capa').addClass('categoria-activa');
            $('#input_'+_cat).val(1);
          }
          else{
            $(this).find('.capa').removeClass('categoria-activa');
            $('#input_'+_cat).val('');
          }
        });

        initGA();

        $('.js-configurar').on('click', function(){
            $('.js-formulario-cookies').show();
        });
        $('.tab-filtro').on('click',function(){
          $(this).find(".js_tipo-red").trigger("click");
        });
        $('.js_tipo-red').on('click', function(e){
            e.stopPropagation();
            if ($(this).hasClass('js-tiporedactiva')){
                console.log("### >>> elmino activa");

                //si está activada la desactivo
                $('.js_tipo-red').removeClass('js-tiporedactiva');
                $('.js_tipo-red').parent().removeClass('selected');
                $('.js-red').removeClass('js-redinactiva');
            }
            else{//activar tipo porque ya estaba activado
               
                //desactivo los 3 botones y activo solo el clickado
                $('.js_tipo-red').removeClass('js-tiporedactiva');
                $('.js_tipo-red').parent().removeClass('selected');
                $(this).addClass('js-tiporedactiva');
                $(this).parent().addClass('selected');
                
                if ($(this).attr('data-tipored') == 'IN'){
                  $('.js-red[data-in!="1"]').addClass('js-redinactiva');
                  $('.js-red[data-in="1"]').removeClass('js-redinactiva');
                  $('#tipo_in').val(1);
                  $('#tipo_out').val(0);
                }
                else{
                  if ($(this).attr('data-tipored') == 'OUT'){
                    $('.js-red').addClass('js-redinactiva');
                    $('.js-red[data-out="1"]').removeClass('js-redinactiva');
                    $('#tipo_in').val(0);
                    $('#tipo_out').val(1);
                  }
                  else{
                    $('.js-red').removeClass('js-redinactiva');
                    $('#tipo_in').val(1);
                    $('#tipo_out').val(1);
                  }
                }
            }   
            $('.js-red').removeClass('js-seleccionada');

            setTimeout(function(){
              checkAnchoItems();
            },0);
        });
        function checkAnchoItems(){
          if(anchoventana>md){
            anchoItem=$(".js-red:first-child").width()+15;
            totalItems=$(".js-red").not(".js-redinactiva").length;
            valorMaximo=(totalItems-2)*anchoItem;
            if($(window).width()>totalItems*anchoItem){
              valorMaximo="100%";
            }
            $(".js-contenedor-network").css("max-width",valorMaximo);
          }
        }
        checkAnchoItems();

        $('.js-empezar').on('click', function(){
          if (parseInt($('#numcategorias').val())==1 ){
            if (parseInt($('#numsubcategorias').val())==1){
              document.location.href = "centros.php";
            }
            else{
              document.location.href = "planes.php";
            }  
          }
          else{
            document.location.href = "redes.php";
          }  
        });

        $('.js-red').on('click', function(){
          var red_sel = $(this).attr('data-red');
        //if (!$(this).hasClass('js-redinactiva')){ //si está inactiva, no se puede seleccionar
            if ($(this).hasClass('js-seleccionada')){
                //si ya estaba seleccionada --> quitar
                $(this).removeClass('js-seleccionada');
                $('#red-'+red_sel).val('0');
            }
            else{//no estaba seleccionada --> seleccionar
                $(this).addClass('js-seleccionada');
                $('#red-'+red_sel).val('1');
            }
          //}  
        });

        $('.js-idiomas').on('change', function(){
          var _datos = 'idioma='+$(this).val();
            $.ajax({
              url: "ajax/ajax-idioma.php",
							type: "POST",
							dataType: "json",
							data: _datos,
              success: function (response) {
                console.log('pp');
                location.reload(); 
              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }        
            });
        });

        $('.js-logout').on('click', function(){
            $.ajax({
              url: "ajax/ajax-logout.php",
							type: "POST",
							dataType: "json",
              success: function (response) {
                document.location.href = "index.php";
              },
              error: function (xhr, status) {
              },
              complete: function (xhr, status) {
              }        
            });
        });
        
        var mySwiperCarousel = new Swiper('.swiper-carousel', {
          loop: false,
          slidesPerView: 6,
          autoplay: {
            delay: 2000,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true 
          },
          navigation: {
            nextEl: '#swiper-button-next-carousel',
            prevEl: '#swiper-button-prev-carousel',
          },
          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }
        });

        var mySwiperTestimonials = new Swiper('.swiper-testimonials', {
          loop: false,
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
          },          
          pagination: {
            el: '.swiper-pagination',
            clickable: true 
          },
          navigation: {
            nextEl: '#swiper-button-next-testimonials',
            prevEl: '#swiper-button-prev-testimonials',
          },
        });

        $( window ).resize(function() {
          checkAnchoItems();
        });

        $('.js-registrate').on('click', function(){
          $('#profile-tab').trigger('click');
        });

        $('.js-ojo').on('click', function(){
            var _el = $(this).attr('data-idmostrar');
            if ($(this).hasClass('pass_oculta')){
              $('#'+_el).attr('type', 'input');
              $(this).removeClass('pass_oculta');
              $(this).addClass('pass_visible');
              $(this).find('img').attr('src', 'dist/images/ico-ver.svg');
            }else{
              $('#'+_el).attr('type', 'password');
              $(this).addClass('pass_oculta');
              $(this).removeClass('pass_visible');
              $(this).find('img').attr('src', 'dist/images/ico-ver-on.svg');
            }
        });

        $('.js-editNombre').on('click', function(){
          if ($('#nombreupd').prop('readonly')){//si es readonly --> dejar modificar
            $('#nombreupd').prop('readonly', false);
            $('#icoeditar').attr('src', 'dist/images/ico-guardar.svg');
            $('#icoeditarpass').attr('src', 'dist/images/ico-noeditar.svg');
            $('.js-irmodificar').addClass('no-cursor');
          }else{
            if ($('#nombreupd').val() != ''){
              
              $.ajax({
                url: "ajax/ajax-cambiar-nombre.php",
                data: "&nombreupd="+$('#nombreupd').val(),
                type: "POST",
                dataType: "json",
                success: function (response) {
                  var obj, target, spinner_holder;
                  $('#nombreupd').prop('readonly', true);
                  $('#icoeditar').attr('src', 'dist/images/ico-editar.svg');
                  $('#icoeditarpass').attr('src', 'dist/images/ico-editar.svg');
                  $('.js-irmodificar').removeClass('no-cursor');
                  $('#txtnombre').html($('#nombreupd').val());
                },
                error: function (xhr, status) {
                  PLG_showModalAlerta("Oops!", 'ERROR');
                },
                complete: function (xhr, status) {
                }
    
              });
            }else{
              var _err = $('#nombreupd').closest('.form-group').attr('data-mensaje-error');
              PLG_showModalAlerta($('#txtups').val(), _err);
            }  
            
          }  
          
        });

        $('.js-irmodificar').on('click', function(){
          if ($('#nombreupd').prop('readonly')){//si no es readonly --> dejar modificar contraseña
             document.location.href = "modificar.php";              
          }
        });  
        
        $('.js-cerrar').on('click', function(){
          $("#modal-alerta").modal("hide");
        });

        $('.solo-letras').on('keypress', function(event) {
          return soloLetras(event);
        });
        $('.solo-numeros').on('keypress', function(event) {
          return soloNumeros(event);
        });
        $('.formato-fecha').on('keypress', function(event) {
          return formatoFecha(event);
        });
        $('.js-forzar-mayusculas').on('keypress', function(){
          valor = $(this).val().toUpperCase();
          $(this).val(valor); 
        });
        function soloLetras(e){
            key = e.keyCode || e.which;
            tecla = String.fromCharCode(key).toLowerCase();
            letras = " áéíóúabcdefghijklmnñopqrstuvwxyz";
            especiales = "8-37-39-46";

            tecla_especial = false
            for(var i in especiales){
                  if(key == especiales[i]){
                      tecla_especial = true;
                      break;
                  }
              }

              if(letras.indexOf(tecla)==-1 && !tecla_especial){
                  return false;
              }
        }
        function soloNumeros(e){
              key = e.keyCode || e.which;
              tecla = String.fromCharCode(key).toLowerCase();
              letras = "0123456789";
              especiales = "8-37-39-46";

              tecla_especial = false
              for(var i in especiales){
                    if(key == especiales[i]){
                        tecla_especial = true;
                        break;
                    }
                }

                if(letras.indexOf(tecla)==-1 && !tecla_especial){
                    return false;
                }
        }
        function formatoFecha(e){
              key = e.keyCode || e.which;
              tecla = String.fromCharCode(key).toLowerCase();
              letras = "/0123456789";
              especiales = "8-37-39-46";

              tecla_especial = false
              for(var i in especiales){
                    if(key == especiales[i]){
                        tecla_especial = true;
                        break;
                    }
                }

                if(letras.indexOf(tecla)==-1 && !tecla_especial){
                    return false;
                }
        }
          


        //ANTI-PASTE
        $('.anti-paste').bind("cut copy paste",function(e) {
            e.preventDefault();
        });

        //OFERTAS
        if ($('#map').length>0) {
            //MIXPANEL #2
            console.log("Mixpanel #2 Offers page viewed");
            setTimeout(function(){
              if (typeof mixpanel != "undefined") {
                console.log("MIXreal>>>>>Offers page viewed");
                mixpanel.track("Offers page viewed", {
                  "Result count": $(".grid-items .item").length
                })
              }  
            },2000)
            function initMap_inicial() {
              $( "#slider" ).slider({
                max: $('#radius_max').val(),
                min: $('#radius_min').val(),
                value: $('#radius_default').val(),
                step: 5	
              });
              function initSliderStart(){
                if (!$('#slider .slider-start').length){
                  $("#slider").append("<div class='slider-start'></div>")
                }
                if ($('#slider .slider-start').length){
                  setTimeout(function(){
                    $('.slider-start').width($('#slider .ui-slider-handle')[0].style.left);
                  },25)
                }
              }
              initSliderStart();
              $("#slider").on("slidestop", function(event, ui) {
                endPos = ui.value;
                $('.label-slider').html(endPos+' '+_medida);
                $('#distancia').val(endPos);
                $(".js-boton-radio").trigger("click");
              });
              $( "#slider" ).on( "slide", function( event, ui ) {
                endPos = ui.value;
                $('.label-slider').html(endPos+' '+_medida);
                $('#distancia').val(endPos);
                initSliderStart();
              } );            
              map = new google.maps.Map(document.getElementById('map'), {
                  zoom: 5
              });
              const options = {
                fields: ["formatted_address", "geometry", "name"],
                origin: map.getCenter()				
              };
              const input = document.getElementById("pac-input");
              autocomplete = new google.maps.places.Autocomplete(input, options);	
              autocomplete.addListener("place_changed", function(event) {
                  const place = autocomplete.getPlace();
                  if (!place.geometry || !place.geometry.location) {
                    // window.alert("No details available for input: '" + place.name + "'");
                    $(".cont-slider").addClass("disabled");
                    
                    return;
                  }
                  var lat_input = place.geometry.location.lat();
                  var lng_input = place.geometry.location.lng();
                  // If the place has a geometry, then present it on a map.
                  $('#latitud').val(lat_input);
                  $('#longitud').val(lng_input);
        
                  $(".cont-slider").removeClass("disabled");
                  $(".js-boton-radio").trigger("click");
                  
              });
            }
            var oms;
            var autocomplete;
            function initMap() {
              console.log(">>>>initMap");
              icon= {
                url:$('#url_archivo').val()+"dist/images/marker2.png",
                scaledSize: new google.maps.Size(22, 40)
              };
              infowindow = new google.maps.InfoWindow();
              geocoder = new google.maps.Geocoder();
            
            
              var prim_ln = 0;
              var prim_lt = 0;
              $.each(marcadoresCompletos, function(index, val) {
                val2="["+val+"]";
                val2=eval(val2);
                val2 = val2[0];
                //if (parseInt(val2.tipo) != 1){
                if ((parseInt(val2.tipo) != 1 ) && !($('#'+val2.item).hasClass('oculta'))){
                  if (val2.lat!=0 && val2.lng!=0){
                    prim_ln = val2.lng;
                    prim_lt = val2.lat;
                  }
                }
              });
              map = new google.maps.Map(document.getElementById('map'), {
                //center: {lat: prim_lt, lng: prim_ln},
                  center: {lat: 39.399872, lng: -8.224454},
                 	zoom: 7
              });
              var mapHasIdled = false;
              google.maps.event.addListener(map, 'idle', function() { 
                if(!mapHasIdled){
                  ponerMarcadores();
                }
                mapHasIdled = true; 
              });
            }
            function ponerMarcadores(){
              oms = new OverlappingMarkerSpiderfier(map, {
                        nearbyDistance: 10, //20 default
                        keepSpiderfied:true,
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: false
              });
              oms.addListener('format', function(marker, status) {
                var iconURL = 
                (status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED) ? $('#url_archivo').val()+"dist/images/marker2.png" :
                (status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) ? $('#url_archivo').val()+"dist/images/group1.png":
                (status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE) ? $('#url_archivo').val()+"dist/images/marker-transparent.png":
                (status === OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIED) ? $('#url_archivo').val()+"dist/images/group1.png":
                null;
        
                var iconSize = new google.maps.Size(23, 32);
                marker.setIcon({
                  url: iconURL,
                  //size: iconSize,
                  scaledSize: iconSize  // makes SVG icons work in IE
                });
              });
        
              var items = [];
              i=0;
              var bounds = new google.maps.LatLngBounds();
              $.each(marcadoresCompletos, function(index, val) {
                val2="["+val+"]";
                val2=eval(val2);
                val2 = val2[0];
                if(val2.tipo!=1){
                  if (val2.lat!=0 && val2.lng!=0){
                    if (!$('.item[id='+val2.item+']').hasClass('oculta')  ){
                      var latLng = new google.maps.LatLng(val2.lat, val2.lng);
                      var marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        id: val2.item, 
                        icon: icon,
                        title: val2.item
                      });
                      bounds.extend(latLng);
                      if (parseInt(val2.tipo) == 1){								
                        var details = "<strong>"+$('#experiencias_encasa').val()+"</strong>";
                      }	
                      else{
                        var details = "<strong>"+val2.centro+"</strong><br/>"+val2.titulo+"<br/>"+val2.direccion+"<br/><a class='js-marker' href='javascript:;'  onclick='abrir(\""+val2.item+"\")' >"+$('#experiencias_seleccionar').val()+"</a>";
                      }	
                      
                      marcadores[val2.item] = marker;
                      //bindInfoWindow(marcadores[val2.item], map, infowindow, details);
                      google.maps.event.addListener(marker, 'spider_click', function(e) { 
                        infowindow.setContent(details);
                        infowindow.open(map, marker);
                        clickMapPin();
                        if ($(window).width() < 767){
                          PLG_scrollTo(2,$('#'+marker.id),0,20);
                        }
                        else{	
                          PLG_scrollTo(2,$('#'+marker.id),0,-330);
                        }	
                        $('.js-item-listado').removeClass('active');
                        $('#'+marker.id).addClass('active');
                      });

      
        
                      oms.addMarker(marker); 
                    };
                  }
                }	
        
              });
        
              i++;
              if (i>=10){
                //return false;
              }
              //map.fitBounds(bounds);
              markerCluster = new MarkerClusterer(map, marcadores, {
                //imagePath: "https://unpkg.com/@googlemaps/markerclustererplus@1.0.3/images/m",
                imagePath:$('#url_archivo').val()+"dist/images/grouping",
                minimumClusterSize: 10
              });
              map.addListener('dragend', function() { 
                oms.formatMarkers();
              } );
            };	 

        }
        
        function bindInfoWindow(marker, map, infowindow, strDescription) {
          google.maps.event.addListener(marker, 'click', function() {
            infowindow.setContent(strDescription);
            infowindow.open(map, marker);
            //console.log(marker.title);
            if ($(window).width() < 767){
              PLG_scrollTo(2,$('#'+marker.id),0,20);
            }
            else{	
              PLG_scrollTo(2,$('#'+marker.id),0,-330);
            }	
            $('.js-item-listado').removeClass('active');
            $('#'+marker.id).addClass('active');
            
            
          });
        }
        //initMap_inicial();
        //initMap();

        var _provtxt = $('#provincia_sel').val();

        if (_provtxt == ''){
          $('#selRegiones').val('');
          console.log('selecciona vacio');
        }else{
          _provtxt = _provtxt.replace(/\'/g, "");
          $('#selRegiones').val(_provtxt);

          console.log('selecciona '+_provtxt);
        }
        $('#selRegiones').trigger('chosen:updated');
        $('.js-boton-descarga').show();
        
        $("#selActivities option").each(function(){
          if ($(this).val() != "" && jQuery.inArray( $(this).val(), _actividades_incluidas ) < 0){        
            $("#selActivities").find("option[value='"+$(this).val()+"']").remove(); 
            $('#selActivities').trigger("chosen:updated");  
          }				
        });
        
        if ($("#selActivities option").length > 1){
          //$("#selActivities").find("option[value='']").remove(); 
          $('#selActivities').prepend("<option value='' selected='selected' >"+$('#experiencias_todos').val()+"</option>");
          $('#selActivities').trigger("chosen:updated");  
          $('#selActivities_chosen').show();
        }
        else{
          $("#selActivities_chosen").hide();
        }

        $('.close').on('click', function(){
          $(this).closest('.modal').hide();
        });

        $('.js-listado').on('click', function(){
          $('#mapa').hide();
          
          $('.js-item-listado').removeClass('active');

          $(this).addClass('active');
          $('.js-mapa').removeClass('active');

          //cambiamos clases col
          $("#listado").removeClass("col-md-5");
          $(".grid-items .item").addClass("col-md-6");
          $(".grid-items .item").addClass("col-lg-5");
        });
        $('.js-mapa').on('click', function(){
            //MIXPANEL
            /*if (typeof mixpanel != "undefined") {
              mixpanel.track("Map Showing", {
                "Click": true
              });	
            }	*/
            initMap();
            
            $('#mapa').show();
            /*if ($(window).width() > 767){
              PLG_scrollTo(1,$('#listado'),0,-275);
            }
            else{
              PLG_scrollTo(1,$('#listado'),0,-580);
            }*/
            $(this).addClass('active');
            $('.js-listado').removeClass('active');

            //cambiamos clases col
            $("#listado").addClass("col-md-5");
            $(".grid-items .item").removeClass("col-md-6");
            $(".grid-items .item").removeClass("col-lg-5");				
        });

        //MIXPANEL
        filterused=false;

        $('.js-activities').on('change', function(){
          //MIXPANEL
          filterused=true;
          filtrar();

          //MIXPANEL #3
          console.log("Mixpanel #3 Click Offers selector");
          if (typeof mixpanel != "undefined") {
            mixpanel.track("Offers selector", {
              "Click": true
            });	
          }
        });
        $('.js-creditos').on('change', function(){
          filtrar();
        });
        $('.js-regiones').on('change', function(){
          filtrar();

          //MIXPANEL #4
          console.log("Mixpanel #4 Click Region selector");
          if (typeof mixpanel != "undefined") {
            mixpanel.track("Region selector", {
              "Click": true
            });	
          }
        });

        function filtrar(){
          var _valA = $('.js-activities').val();
          var _valC = 0;//parseInt($('.js-creditos').val());
          var _valR = $('.js-regiones').val();

          $('.js-actividades').removeClass('oculta');
          
          if (_valA != ''){
            //$('.js-actividades[data-Activity="'+_valA+'"]').removeClass('oculta');				
            $('.js-actividades[data-Activity!="'+_valA+'"]').addClass('oculta');
          }	
          if (_valR != ''){
            //$('.js-actividades[data-Activity="'+_valA+'"]').removeClass('oculta');				
            $('.js-actividades[data-RegionBusqueda!="'+_valR+'"]').addClass('oculta');
          }
          
          var _mins = [0, 10, 30, 60];
          var _maxs = [9, 29, 59, 9999]
          var _ind = _valC - 1;
          if (parseInt(_valC) != 0){

            $('.js-actividades').each(function(index, el) {
              if (!$(this).hasClass('oculta')){
                var _credit = parseInt($(this).attr('data-PartnerOfferRRP'));
                //console.log(_credit+'>>'+_ind);
                if (_credit < _mins[_ind] || _credit > _maxs[_ind]){
                  $(this).addClass('oculta');
                }
              }	
            });
          }
          
          marcadores = [];
          map = null;
          setTimeout(function(){
              initMap();
          },100)


          // if ($('.js-listado').hasClass('active')){
          //   $('#mapa').hide();
          // }
          // else{
          //   //initMap();
          //   $('#mapa').show();
          // }	


          
          $('#txtfiltro').empty();
          var ul = document.getElementById("txtfiltro");
          
          
          if (_valA != ''){					
            var li = document.createElement("li");
            li.appendChild(document.createTextNode($('.js-activities option:selected').text()));
            ul.appendChild(li);
          }

          if (parseInt(_valC) != 0){					
            var li = document.createElement("li");
            li.appendChild(document.createTextNode($('.js-creditos option:selected').text()));
            ul.appendChild(li);
          }

          if (_valA != '' || parseInt(_valC) != 0){
            $('.js-borrar').show();
          }else{
            $('.js-borrar').hide();
          }

          var html_experiencias = $('#centros_total2').html();
          if ($(".js-actividades:visible").length <= 1){
            html_experiencias = $('#centros_total2_sing').html();
          }
          if ($(".js-actividades:visible").length == 0){
            $('#txttotal_busqueda').html($('#centros_no_centros').html());

          }else{
            $('#txttotal_busqueda').html($('#centros_total1').html() + $(".js-actividades:visible").length + html_experiencias);
          }
          //$('#txttotal_busqueda').html($('#centros_total1').html() + $(".js-actividades:visible").length + html_experiencias);
        }

        $('.js-borrar').on('click', function(){
          $('.js-activities').val('');
          $('.js-creditos').val(0);
          $('.js-activities').trigger("chosen:updated");
          $('.js-creditos').trigger("chosen:updated");
          filtrar();
        });
          
        $('.js-boton-descarga').on('click', function(){
        btdescarga($(this));
          
        });

        function calculaTopGrid(){
          setTimeout(function(){
            alto=$(".seleccion").height()+$("header").height();
            $(".grid-items").css("margin-top",alto);
          },100)
        }

        $('.titulofiltro').on('click', function(){
          if ($(window).width() <= 768){
            
            if ($(this).closest('.filtro').hasClass('activo')){

              //$(this).closest('.filtro').find('.bocadillo').show();
              $(this).closest('.filtro').removeClass('activo');
              $(this).closest('.filtro').find('.bocadillo').fadeOut('fast');
              $(this).closest('.filtro').find('.bocadillo').css('opacity', '0');
              $(this).closest('.filtro').find('.bocadillo').find('.mod-opciones').hide();	

            }else{

              $(".filtro.activo").each(function(index, el) {
                $(this).removeClass('activo');
                $(this).find('.bocadillo').fadeOut('fast');
                $(this).find('.bocadillo').css('opacity', '0');
                $(this).find('.bocadillo').find('.mod-opciones').hide();	
              });


              $(this).closest('.filtro').addClass('activo');
              $(this).closest('.filtro').find('.bocadillo').fadeIn('fast');
              $(this).closest('.filtro').find('.bocadillo').css('opacity', '1');
              $(this).closest('.filtro').find('.bocadillo').find('.mod-opciones').show();	

            }
              
          }
        });
            
        $('.js-item-listado').each(function( index ) {
          var _m = true;
          if (_cookie_in != _cookie_out){
            if (_cookie_in == 1 && parseInt($(this).attr('data-typeinout')) != 1 ){
              _m = false;
            }
            if (_cookie_out == 1 && parseInt($(this).attr('data-typeinout')) != 2 ){
              _m = false;
            }
          }	
          
          if (!_m){
            $(this).addClass('oculta_siempre');
          }


        

        });	

        $('.js-boton-radio').on('click', function(){
          if ($('#pac-input').val() != ''){
            $('#listado').hide();
            $('#txt_resultados').hide();
            $('#carga').show();
            $('#puede_redimir').val(true);
            var _datos = $('#fRadio').serialize();
            $.ajax({
              url: "ajax/ajax-radio-centros.php",
              type: "POST",
              dataType: "json",
              data: _datos,
              success: function (response) {            
                $('.grid-items').html(response.html);
                setTimeout(function(){ 
                  if (response.html == ''){
                    //$('#map').hide();
                    
                    marcadoresCompletos2 = response.marcadores;
                    marcadoresCompletos = [];
                    $.each(marcadoresCompletos2, function(index, val) {
                      marcadoresCompletos[index] = val.replaceAll("\r\n", ' ');
                    });
                    _actividades_incluidas = response.actividades_incluidas;
                    marcadores = []; 
                    initMap();
                    initEventosMapa();
                    $('#txt_resultados span').html(response.distancia);
                    $("#selActivities option").each(function(){
                      if ($(this).val() != "" && jQuery.inArray( $(this).val(), _actividades_incluidas ) < 0){   
                        $("#selActivities").find("option[value='"+$(this).val()+"']").remove(); 
                        $('#selActivities').trigger("chosen:updated");  
                      }				
                    });
                    $('#menu_filtros').addClass('oculta');
                    $('#mapa').hide();
                  }
                  else{
                    $('#txt_resultados span').html(response.txtresultado);
                    marcadoresCompletos2 = response.marcadores;
                    marcadoresCompletos = [];
                    $.each(marcadoresCompletos2, function(index, val) {
                      marcadoresCompletos[index] = val.replaceAll("\r\n", ' ');
                    });
                    _actividades_incluidas = response.actividades_incluidas;
                    marcadores = []; 
                    initMap();
                    initEventosMapa();
                    $("#selActivities option").each(function(){
                      if ($(this).val() != "" && jQuery.inArray( $(this).val(), _actividades_incluidas ) < 0){   
                        $("#selActivities").find("option[value='"+$(this).val()+"']").remove(); 
                        $('#selActivities').trigger("chosen:updated");  
                      }				
                    });

                    if ($('.js-mapa').hasClass('active')){
                      $("#listado").addClass("col-md-5");
                      $(".grid-items .item").removeClass("col-md-6");
                      $(".grid-items .item").removeClass("col-lg-5");
                    }
                    $('#menu_filtros').removeClass('oculta');
                    $('#mapa').show();
                  }	
                  //console.log(response.actividades_incluidas);
                  $('#listado').show();
                  $('#txt_resultados').show();
                  $('#txttotal_busqueda').hide();
                  $('#carga').fadeOut();
                  $('#menu_filtros').show();
                }, 1000);
                
              },
              error: function (xhr, status) {
                PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
                $('#listado').show();
                $('#txt_resultados').show();
                $('#carga').fadeOut();
              },
              complete: function (xhr, status) {
              }
            });
          }
          else{
            PLG_showModalAlerta($('#txtups').val(), $('#search_error1').val());
            
          }	
        });

        function initEventosMapa(){
          $('.js-item-listado').on('click', function(e){
            if ($('#navmapa').hasClass("active")){
              $('#modal-IN').modal('hide');
              if (parseInt($(this).attr('data-typeinout')) != 1){
                $('.js-marker').closest('.gm-style-iw-t').hide();
                var id = $(this).attr('id').split('_')[1];
                var m = "["+marcadoresCompletos[id]+"]";
                m=eval(m);
                m = m[0];
                var marc = marcadores[m.item];
                google.maps.event.trigger (marc, 'click');
                if ($(window).width() < 767){
                  PLG_scrollTo(1,$('#mapa'),0,-10);
                }
                if(map!=null){
                  $('.js-item-listado').removeClass('active');
                  $(this).addClass('active');
                }
              }
              else{
                if (!$('#modal-descargabono').hasClass("show")){
                  $('#modal-IN').modal('show');
                }	
              }
            }	
          });
          $('.js-boton-descarga').on('click', function(){
            btdescarga($(this));
          });
        }

        var html_experiencias = $('#centros_total2').html();
        if ($(".js-actividades:visible").length == 1){
          html_experiencias = $('#centros_total2_sing').html();
        }

        if ($(".js-actividades:visible").length == 0){
          $('#txttotal_busqueda').html($('#centros_no_centros').html());

        }else{
          $('#txttotal_busqueda').html($('#centros_total1').html() + $(".js-actividades:visible").length + html_experiencias);
        }	

        setCookie("tlcpromo_provincias", '', 365);
        setCookie("tlcpromo_subcategorias", '', 365);	
        setCookie("tlcpromo_in", '', 365);
        setCookie("tlcpromo_out", '', 365);
        
        $('#modal-descargabono').on('shown.bs.modal', function (e) {
          $("body").css("overflow","hidden");
          $("body").css("position","fixed");
          $("body").css("width","100%");
        });
        
        $('#modal-descargabono').on('hidden.bs.modal', function (e) {
          $("body").css("overflow","inherit");
          $("body").css("position","relative");
        });		
        
        $('form#fRadio'). keydown(function (e) {
          if (e. keyCode == 13) {
            e. preventDefault();
            return false;
          }
        });

        var _cookie_in = 1;
        var _cookie_out = 1;

        var map;
        var icon;

        var infowindow;
        var geocoder;
        var marcadores = []; 
        var _medida = $('#medida').val();

        $('#btn-enviar-bono').on('click', function() {
            var error = false;
            var _key_captcha = $('#key_captcha').val();
          
            grecaptcha.ready(function() {
            grecaptcha.execute(_key_captcha, {action: 'contactpage'}).then(function(token){
              $('#descargar-bono1').prepend('<input type="hidden" name="tokenGoogle" value="' + token + '">');   
                var form = document.getElementById('descargar-bono1');
                var formJS = $('#descargar-bono1');
    
                var _errors= '';

                var name = document.getElementById('idNombre');
                //var lastname = document.getElementById('deApellidos');
                var itTelefono = document.getElementById('itTelefono');
                var conhecimento = document.getElementById('conhecimento');
                var email = document.getElementById('deEmail');
                var itConf = document.getElementById('deConf');
                var deStore = document.getElementById('deStore');
                var deStoreLocation = document.getElementById('deStoreLocation');
                var deQuantity = document.getElementById('deQuantity');

                var offer_selected = document.getElementById('deCentro');
                var checkLegal1 = document.getElementById('check-bono-legal1');

                //var checkLegal4 = document.getElementById('check-legal4');
                //var checkLegal2 = document.getElementById('check-bono-legal2');
                var ficfoto1 = document.getElementById('ficfoto1');
                var guest = parseInt($('#guests').val());

                if (guest > 0){
                  var name1 = document.getElementById('idNombre1');
                  var lastname1 = document.getElementById('deApellidos1');
                  var name2 = document.getElementById('idNombre2');
                  var lastname2 = document.getElementById('deApellidos2');
                  var name3 = document.getElementById('idNombre3');
                  var lastname3 = document.getElementById('deApellidos3');
                  var name4 = document.getElementById('idNombre4');
                  var lastname4 = document.getElementById('deApellidos4');
                  var name5 = document.getElementById('idNombre5');
                  var lastname5 = document.getElementById('deApellidos5');
                }  
                if (guest > 0 && (name1.value == '' || lastname1.value == '')){
                  $('#idNombre1').prop('required',true);
                  $('#deApellidos1').prop('required',true);
                }  
          
                if (guest > 1 && (name2.value == '' || lastname2.value == '')){
                  $('#idNombre2').prop('required',true);
                  $('#deApellidos2').prop('required',true);
                } 
          
                if (guest > 2 && (name3.value == '' || lastname3.value == '')){
                  $('#idNombre3').prop('required',true);
                  $('#deApellidos3').prop('required',true);
                } 
          
                if (guest > 3 && (name1.value == '' || lastname1.value == '')){
                  $('#idNombre4').prop('required',true);
                  $('#deApellidos4').prop('required',true);
                } 
          
                if (guest > 4 && (name1.value == '' || lastname1.value == '')){
                  $('#idNombre5').prop('required',true);
                  $('#deApellidos5').prop('required',true);
                } 

                if (form.checkValidity() === false || $('#deEmail').val() != $('#deConf').val() || isNaN($('#deQuantity').val()) || parseInt($('#deQuantity').val()) < 2 ) {
                    error = true;
                    if (!name.validity.valid) _errors += ("<li>" + $('#idNombre').closest('.form-group').data('mensaje-error') + "</li>");
                    //if (!lastname.validity.valid) _errors += ("<li>" + $('#deApellidos').closest('.form-group').data('mensaje-error') + "</li>");
                    if (!email.validity.valid) _errors += ("<li>" + $('#deEmail').closest('.form-group').data('mensaje-error') + "</li>");
                    if ($('#deEmail').val() != $('#deConf').val()){
                      _errors += ("<li>" + $('#deConf').closest('.form-group').data('mensaje-error') + "</li>");
                    }
                    if (!itTelefono.validity.valid) _errors += ("<li>" + $('#itTelefono').closest('.form-group').data('mensaje-error') + "</li>");
                    if (!conhecimento.validity.valid) _errors += ("<li>" + $('#conhecimento').closest('.form-group').data('mensaje-error') + "</li>");
                    
                    if (!deStore.validity.valid) _errors += ("<li>" + $('#deStore').closest('.form-group').data('mensaje-error') + "</li>");
                    if (!deStoreLocation.validity.valid) _errors += ("<li>" + $('#deStoreLocation').closest('.form-group').data('mensaje-error') + "</li>");
                    
                    if (isNaN($('#deQuantity').val())  || ($('#deQuantity').val()) < 2 ){
                      _errors += ("<li>" + $('#deQuantity').closest('.form-group').data('mensaje-error') + "</li>");
                    }
                    

                    if (!checkLegal1.validity.valid) _errors += ("<li>" + $('#check-bono-legal1').closest('.form-group').data('mensaje-error') + "</li>");
                    //if (!checkLegal4.validity.valid) _errors += ("<li>" + $('#check-legal4').closest('.form-group').data('mensaje-error') + "</li>");

                    //if (!checkLegal2.validity.valid) _errors += ("<li>" + $('#check-bono-legal2').closest('.form-group').data('mensaje-error') + "</li>");
                    
                    if (guest > 0){
                      if (!name1.validity.valid) _errors += ("<li>" + $('#idNombre1').closest('.form-group').data('mensaje-error') + "</li>");
                      if (!lastname1.validity.valid) _errors += ("<li>" + $('#deApellidos1').closest('.form-group').data('mensaje-error') + "</li>");
                    }
                    if (guest > 1){
                      if (!name2.validity.valid) _errors += ("<li>" + $('#idNombre2').closest('.form-group').data('mensaje-error') + "</li>");
                      if (!lastname2.validity.valid) _errors += ("<li>" + $('#deApellidos2').closest('.form-group').data('mensaje-error') + "</li>");
                    }
          
                    if (guest > 2){
                      if (!name3.validity.valid) _errors += ("<li>" + $('#idNombre3').closest('.form-group').data('mensaje-error') + "</li>");
                      if (!lastname3.validity.valid) _errors += ("<li>" + $('#deApellidos3').closest('.form-group').data('mensaje-error') + "</li>");
                    }
          
                    if (guest > 3){
                      if (!name4.validity.valid) _errors += ("<li>" + $('#idNombre4').closest('.form-group').data('mensaje-error') + "</li>");
                      if (!lastname4.validity.valid) _errors += ("<li>" + $('#deApellidos4').closest('.form-group').data('mensaje-error') + "</li>");
                    }
          
                    if (guest > 4){
                      if (!name5.validity.valid) _errors += ("<li>" + $('#idNombre5').closest('.form-group').data('mensaje-error') + "</li>");
                      if (!lastname5.validity.valid) _errors += ("<li>" + $('#deApellidos5').closest('.form-group').data('mensaje-error') + "</li>");
                    }

                    
                    if (!ficfoto1.validity.valid) _errors += ("<li>" + $('#ficfoto1').closest('.form-group').data('mensaje-error') + "</li>");
                  }
                                
                formJS.addClass('was-validated');
                if (!error) {

                  if ($('#check-legal3').prop('checked')){
                    $('#check-legal3').val('YES');
                  }else{
                    $('#check-legal3').val('NO');
                  }

                  if ($('#check-bono-legal2').prop('checked')){
                    $('#check-bono-legal2').val('YES');
                  }else{
                    $('#check-bono-legal2').val('NO');
                  }


                  $('#btn-enviar-bono').addClass('loader');
                  formJS.find('.alert').slideUp("fast");
                  //var datos = formJS.serialize();
                  /*var file1 = $('#ficfoto1');  

                  var archivo = file1[0].files;  
                  var datos = new FormData($('#descargar-bono1').get(0));

                        
    
                  formData.append('files[]',archivo);  */

                  var formData = new FormData();

                  $('#descargar-bono1').find(':input').each(function() {
                      var field = $(this);
                      // Agregar campos al FormData solo si tienen un nombre
                      if (field.attr('name')) {
                          formData.append(field.attr('name'), field.val());
                      }
                  });

                  var fileInput = document.getElementById('ficfoto1');
                  var file = fileInput.files[0];
                  formData.append('files', file);

                  

                  $.ajax({
                      method: "POST",
                      url: "ajax/ajax-descargar-bono.php",
                      data: formData,
                      contentType: false,
                      processData: false
                    })
                    .done(function(resultAjax) {
                      formJS.removeClass('was-validated');
                      respuestaBono(resultAjax);
                      $('#btn-enviar-bono').removeClass('loader');
                  });
                } else {
                  /*formJS.find('.alert').slideDown(function() {});
                  plg.PLG_scrollTo(1, "#section-user", 0, 100);*/
                  PLG_scrollTo(1,$('#alerta-errores-descargabono'),0,-120);
                  $('#alerta-errores-descargabono').find('span').html($('#descargar-bono1').attr('data-mensaje-error')+'<ul>'+_errors+'</ul>');
                  $('#alerta-errores-descargabono').show();

                }




            
              });
          });
        });   
      },      


      finalize: function() {
      }
    },

    'planes' : {
      init: function(){
        $(".filtro").click(function(){
          id=$(this).attr('id');
          $(".filtro").not('#' + id).removeClass("activo");
          $(this).toggleClass("activo");
          //$("body").toggleClass("hidescroll");
        })
      },
      finalize:function(){
        
      }
    }

  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function abrir(numItem){
  //$('.js-boton-descarga[data-item="'+numItem+'"]').trigger('click');
  var itdescarga = $('.js-boton-descarga[data-item="'+numItem+'"]');



  btdescarga(itdescarga);
  //item_12
  //PLG_scrollTo(1,$('.item[id=item_'+numItem+']'),0,-10);
}  

function clickMapPin(){
    //MIXPANEL #5
    console.log("Mixpanel #5 Click Map Pin");
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Map Pin", {
        "Click": true
      });	
    }
}
function btdescarga(_itdescarga){
    $('#modal-IN').hide();
    
    $('.alert').hide();
    $('#id_experience').val(_itdescarga.closest('.item').attr('data-idExperience'));
    _centro = _itdescarga.closest('.item').attr('data-VenueId');
    _nombre_centro = _itdescarga.closest('.item').attr('data-VenueName');
    _experiencia = _itdescarga.closest('.item').attr('data-RewardId');
    _franquicia = _itdescarga.closest('.item').attr('data-HeadOfChainId');
    _nombre_experiencia = _itdescarga.closest('.item').attr('data-RewardName');
    _direccion = _itdescarga.closest('.item').attr('data-Address');
    _telefono = _itdescarga.closest('.item').attr('data-Phone');
    _provincia = _itdescarga.closest('.item').attr('data-Region');
    _poblacion = _itdescarga.closest('.item').attr('data-City');
    _offer_term1 = _itdescarga.closest('.item').attr('data-OfferTerm1');
    _offer_term2 = _itdescarga.closest('.item').attr('data-OfferTerm2');
    _offer_term3 = _itdescarga.closest('.item').attr('data-OfferTerm3');
    _offer_term4 = _itdescarga.closest('.item').attr('data-OfferTerm4');
    _url_image = _itdescarga.closest('.item').attr('data-LargeImageURL');
    _PartnerOfferRRP = _itdescarga.closest('.item').attr('data-PartnerOfferRRP');
    _guests = _itdescarga.closest('.item').attr('data-guests');
    _typeinout = _itdescarga.closest('.item').attr('data-typeinout');
    _web = _itdescarga.closest('.item').attr('data-web');
    _activity = _itdescarga.closest('.item').attr('data-Activity');
    _postcode = _itdescarga.closest('.item').attr('data-Postcode');
    _categoria = _itdescarga.closest('.item').attr('data-Categoria');
    _subcategoriatxt = _itdescarga.closest('.item').attr('data-Subcategoriatxt');
    _offerReference = _itdescarga.closest('.item').attr('data-offerReference');
    //mixpanel
    /*_credits = _itdescarga.closest('.item').find('.creditos').html();
    _credits = _credits.match(/\d+/)[0];*/
  
    
    $("#activity").val(_activity);
    $("#codigo_centro_bono").val(_centro);
    $("#nombre_centro_bono").val(_nombre_centro);
    $('#franquicia').val(_franquicia);
    $("#codigo_experiencia_bono").val(_experiencia);
    $("#nombre_experiencia_bono").val(_nombre_experiencia);
    $("#direccion_centro_bono").val(_direccion);
    $("#telefono_centro_bono").val(_telefono);
    $("#provincia_centro_bono").val(_provincia);
    $("#poblacion_centro_bono").val(_poblacion);
    $("#offer_term1").val(_offer_term1);
    $("#offer_term2").val(_offer_term2);
    $("#offer_term3").val(_offer_term3);
    $("#offer_term4").val(_offer_term4);
    $("#partner_offerRRP").val(_PartnerOfferRRP);
    $("#categoria").val(_categoria);
    $("#subcategoriatxt").val(_subcategoriatxt);
    $('#offerRRP').html(_PartnerOfferRRP);
    $('#offerReference').val(_offerReference);
    
    if (_url_image.indexOf('NET@') == -1){
      //checkimage julio2023
      /*$('#imagen-experiencia').attr('src', _url_image);
      $('#imagen-experiencia').show();*/
  
      //$('#imagen-experiencia').attr('src', _url_image);
      setTimeout(function(){ 
        /*if($('#imagen-experiencia')[0].naturalHeight == 0){
          _url_image = '';	
          
          $('#imagen-experiencia').attr('src', '');
          $('#imagen-experiencia').hide();
  
        }else{
          $('#imagen-experiencia').attr('src', _url_image);
          $('#imagen-experiencia').show();
        } */
        $('#imagen_bono').val(_url_image);
      }, 100);	
  
  
      
      //fin //checkimage julio2023
    }
    else{
      _im = _url_image.substring(4);
      $('#imagen-experiencia').attr('src', _im);
      $('#imagen-experiencia').hide();// 23/11/2020: ahora en vez del logo de la red, quieren que no aparezca nada
      //checkimage julio2023
      $('#imagen_bono').val(_url_image);
      //fin //checkimage julio2023
    }
    //checkimage julio2023 $('#imagen_bono').val(_url_image);
    $('#guests').val(_guests);
    $('#type_inout').val(_typeinout);	
    $('#website').val(_web);
    $("#postcode").val(_postcode);
    
    $('#deCentro').val(_nombre_centro);
    $('#deExpe').val(_offer_term1);
    $('#deDireccion').val(_direccion);
    $('#deTelefono').val(_telefono);
    
    $('.guest').hide();
    $('.guest_1').hide();
    $('.guest_varios').hide();
    if (parseInt(_guests) > 0){
      if (parseInt(_guests) == 1){
        $('.guest_1').show();
        $('.guest_varios').hide();
      }
      else{
        $('.guest_1').hide();
        $('.guest_varios').show();
      }
      $('.guest').each(function(index, el) {
        if (parseInt($(this).attr('data-guest')) <= parseInt(_guests)){
          $(this).show();
        }	
      });
    }
  
    $('#modal-IN').hide();
    //checkimage julio2023
    //$('#modal-descargabono').modal('show');
    setTimeout(function(){ 
      $('#modal-descargabono').modal({
        backdrop: 'static',
        keyboard: false
      });
      $('#modal-descargabono').modal('show');
      //console.log('??');
    }, 200);	
    //fin //checkimage julio2023
    
    // MIXPANEL #6 Registration form loaded
    console.log("Mixpanel #6 Registration form loaded");
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Registration form loaded", {
        "Partner name": _nombre_centro,
        "Experience name": _nombre_experiencia,
        "Filter used": filterused
      });
    }
}
    
  
function resultadoLogin(obj,target, result, spinner_holder){
  if (result['resultado'] == 'OK' ){
    console.log("")
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Login finished", {
        });
      }
      document.location.href = result['redirect'];
  }else{
      $('.btn').removeClass("loader");
      PLG_showModalAlerta_boton($('#txtups').val(), result['error'], result['boton'], 'js-cerrar');
      initEvCerrar();
  }  
}

function initEvCerrar(){
  $('.js-cerrar').on('click', function(){
    $("#modal-alerta").modal("hide");
  });
  $('.js-irexperiencias').on('click', function(){
    $("#modal-alerta").modal("hide");
    document.location.href="redes.php";
  });
}

function resultadoRecordar(obj,target, result, spinner_holder){
  $('.error_noexiste').hide();
  if (result['resultado'] == 'OK' ){
      //document.location.href="resetear.php";
      document.location.href="email_cambio.php";
  }else{
      $('.btn').removeClass("loader");
      $('.error_noexiste').show();
      //PLG_showModalAlerta($('#txtups').val(), result['error']);
  }  
}

function resultadoResetear(obj,target, result, spinner_holder){
  if (result['resultado'] == 'OK' ){
      document.location.href="reseteo_fin.php";
  }else{
      $('.btn').removeClass("loader");
      PLG_showModalAlerta($('#txtups').val(), result['error']);
  }  
}

function resultadoResetear_privada(obj,target, result, spinner_holder){
  if (result['resultado'] == 'OK' ){
      document.location.href="reseteo_fin_privada.php";
  }else{
      $('.btn').removeClass("loader");
      PLG_showModalAlerta($('#txtups').val(), result['error']);
  }  
}

function resultadoComprobarCodigo(obj,target, result, spinner_holder){
 // console.log(result);
  if (result['resultado'] == 'OK' ){
      setCookie("tlcpromo_in", '', 365);
      setCookie("tlcpromo_out", '', 365);
      setCookie("tlcpromo_categorias",'', 365);
      //$('#modalCredito').modal('show');

      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Code entered", {
          "Client Name": result['client_name'],
          "Promotion ID": result['id_promocion']
        });
      }
      document.location.href="validado.php";
  } else if(result['error']) {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
    if (result['error']['msg_error'] == '' || result['error']['msg_error'] == null){
      PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
    }
    else{
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
    }
    
  } else {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
    PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};

function resultadoComprobarCodigoArea(obj,target, result, spinner_holder){
  // console.log(result);
   if (result['resultado'] == 'OK' ){
      $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
      var _creditos_total = result['creditos_total'];
      //console.log(_creditos_total);
      $('#txtcreditostotal').html(_creditos_total);
      // PLG_showModalAlerta($('#enhorabuena').val(), $('#txtenhorabuena').val());

      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Code entered", {
          "Client Name": result['client_name'],
          "Promotion ID": result['id_promocion']
        });
      }
      PLG_showModalAlerta_boton($('#enhorabuena').val()+$('#txtnombre').val()+'!', $('#txtenhorabuena1').val()+result['credit']+$('#txtenhorabuena2').val(), result['boton'], 'js-irexperiencias');
      initEvCerrar();

   } else if(result['error']) {
      $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
     if (result['error']['msg_error'] == '' || result['error']['msg_error'] == null){
       PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
     }
     else{
       PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
     }
     
   } else {
      $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
     PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
   }
};

function resultadoRegistro(obj,target, result, spinner_holder){
 // console.log(result);
  if (result['resultado'] == 'OK' ){
      
        if (parseInt(result['num_categorias']) == 1){
            var _catsel = result['categoria_sel'];//$( ".red-input" ).attr('id')+',';
            
            setCookie("tlcpromo_in", '', 365);
            setCookie("tlcpromo_out", '', 365);

            setCookie("tlcpromo_categorias", _catsel+',', 365);
                     
        }
        $('#numcategorias').val(result['num_categorias']); 
        $('#numsubcategorias').val(result['num_subcategorias']);  
        if (parseInt(result['num_subcategorias']) == 1){
            var _subcatsel = result['subcategoria_sel'];
            setCookie("tlcpromo_subcategorias", _subcatsel+',', 365);
        } 
        //MIXPANEL
        if (typeof mixpanel != "undefined") {
          mixpanel.track("Register finished", {
            "Age": $("#itEdad").val(),
            "Gender": $("#itGenero").val(),
            "TLC opt in": $('#check-legal3').is(':checked')
          });
        }  
        document.location.href="bienvenido.php";

    } else if(result['error']) {
      $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
      $('.inner-contenedor-formulario').find("button[type='submit']").prop('disabled', false);
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
  } else {
     $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
     $('.inner-contenedor-formulario').find("button[type='submit']").prop('disabled', false);
     PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};

function respuestaBono(_result){
  var result = JSON.parse(_result);

  $('#modal-descargabono').modal('hide');
  if (result['result'] == 'OK' ){
      //PLG_showModalAlerta("OKOK", result['error']['msg_error']);
      
      //console.log('total:: '+result['total_creditos']);
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Voucher downloaded");
      }  
      //$('#modal-bonook').modal('show');
      document.location.href="parabens.php";
  } else if(result['error']) {
    $('#btn-enviar-bono').removeClass('loader');
      PLG_showModalAlerta($('#txtups').val(), result['error']['msg_error']);
  } else {
     $('#btn-enviar-bono').removeClass('loader');
    PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
  }
};  

function envio_contacto(obj,target, result, spinner_holder){

  $('.inner-contenedor-formulario').find("button[type='submit']").removeClass('loader');
  if (result['resultado'] == 'OK'){
       $('.contenedor-form').slideUp('fast', function(){
          $('.alerta-gracias').slideDown('slow', function(){
              $('.gracias-contacto').show();
          });

      });
    }else{
      PLG_showModalAlerta($('#txtups').val(), $('#txtgenerico').val());
    }

};

function resultadoCookies(obj,target, result, spinner_holder){
  // console.log(result);
   
  if (result['resultado'] == 'OK' ){
    $("#modal-cookies").fadeOut();
    setCookie("tlcpromo-cookies-tecnica", "true", 365);//la técnica la aprobamos por js como hasta ahora
    
    if (result['estadisticas'] == 'si' && result['codigoGA']!=''){
      //console.log('co::'+result['codigoGA']);
        if (result['codigoGA'].substr(0, 2) == 'UA'){
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
                //'<?=GA_CODE?>'
                  ga('create', result['codigoGA'], 'auto');
                  ga('send', 'pageview');

                  initGA();
              }    
            }
            else{
              (window,document,'script','//https://www.googletagmanager.com/gtag/js?id='+result['codigoGA'],'ga');
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());      
              gtag('config', result['codigoGA']);
            }      
  }
}  
function initGA(){
  $.ajax({
    url: "ajax/ajax-ga.php",
    type: "POST",
    dataType: "json",
    success: function (response) {
      //console.log(response);
      if (response['codigoGA'] != '' && getCookie("tlcpromo-cookies-estadisticas")){
          if (response['codigoGA'].substr(0, 2) == 'UA'){
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              ga('send', 'event', pag, 'CLICK', evento);
              //console.log('GA');
            });  
          }
          else{
            $('.js-activarGA').on('click', function(){
              var pag = $(this).attr('data-pagina');
              var evento = $(this).attr('data-evento');
              gtag('event', pag+'_CLICK_'+evento);
              //console.log('GT');
            });   
          }  
        }    
      },
      error: function (xhr, status) {
      },
      complete: function (xhr, status) {
      }

    });
        


}
